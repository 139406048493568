/* eslint-disable react-hooks/exhaustive-deps */

import React from 'react'
import { dateFormat, getStatus } from '../../utils/constantFunctions';
import { Button } from '@mui/material';
import { useSelector } from 'react-redux';
import logos from "../../assets/img/sidebar/ehadayaDarkLogo.png"
import jsPDF from "jspdf";
import "jspdf-autotable";
import "jspdf-invoice-template";


const MerchandiseTable = ({ orderDetails }) => {

    const accountDetailsState = useSelector((state) => state?.settingState?.accountDetails);
    const settingState = useSelector((state) => state?.settingState?.settingResponse?.site_details);

    const generateShopInvoice = (data) => {
        const doc = new jsPDF("p", "pt");

        // Add title
        const title = "Invoice";
        const titleWidth =
            doc.getStringUnitWidth(title) *
            doc.internal.getFontSize() /
            doc.internal.scaleFactor;
        const titleX = (doc.internal.pageSize.width - titleWidth) / 2;

        // Move the "Invoice" title to the top of the page
        const titleY = 60;
        doc.setFontSize(16);
        doc.text(title, titleX, titleY);

        // Add logo
        const logo = `${settingState?.dark_logo}`;
        doc.addImage(logo, "PNG", 50, 130, 80, 19);

        // Add date
        const date = dateFormat(data?.[0]?.created_at);
        doc.setFontSize(12);
        doc.text(`Date: ${date}`, 40, 220);

        // Get contact information
        const { firstname, lastname, email, contact_no } = accountDetailsState;
        const contactInfo = `${firstname} ${lastname}\n${email}\n${contact_no}`;
        doc.setFontSize(12);
        doc.text(contactInfo, 40, 250);

        // Add ID number
        doc.setFontSize(12);
        doc.text(`Order ID: ${data?.[0]?.id}`, doc.internal.pageSize.width - 235, 222);

        const domainName = process.env.REACT_APP_DOMAIN_NAME;

        // company information
        const companyInfo = `\ninfo@ehadaya.com`;

        // Add company information to PDF
        const companyInfoX = doc.internal.pageSize.width - 235;
        doc.text(domainName, companyInfoX, 250);
        doc.text(companyInfo, companyInfoX, 250);

        // Add table
        doc.autoTable({
            startY: 350,
            head: [["Product Name", "Amount", "Quantity"]],
            body: data.map((row) => [row?.product_name, row?.total_price, row?.quantity]),
            margin: { top: 20 },
            tableWidth: "auto",
            cellPadding: { top: 8, right: 8, bottom: 8, left: 8 }
        });

        // Add subtotal, tax, and total below the table
        const tableBottomY = doc.lastAutoTable.finalY + 20;
        const subtotal = data.reduce((acc, row) => acc + row?.order_price, 0);
        const tax = subtotal * 0.1;
        const total = subtotal + tax;
        doc.setFontSize(12);
        doc.text(`Subtotal: AED ${subtotal.toFixed(2)}`, doc.internal.pageSize.width - 200, tableBottomY + 10);
        // doc.text(`Tax: AED ${tax.toFixed(2)}`, doc.internal.pageSize.width - 200, tableBottomY + 20);
        doc.text(`Total: AED ${subtotal.toFixed(2)}`, doc.internal.pageSize.width - 200, tableBottomY + 40);

        doc.save(`invoice.pdf`);
    };

    return (
        <>
            <div className="table-responsive">
                <table className="table mt-3 w-100 passengertable">
                    <tr>
                        <td className="bg-dark-grey py-2 px-3 fsc-5">Date</td>
                        <td className="bg-dark-grey py-2 px-3 fsc-5">Order Id</td>
                        <td className="bg-dark-grey py-2 px-3 fsc-5">Product Name</td>
                        <td className="bg-dark-grey py-2 px-3 fsc-5">Quantity</td>
                        <td className="bg-dark-grey py-2 px-3 fsc-5">Total Price</td>
                        <td className="bg-dark-grey py-2 px-3 fsc-5">Status</td>
                    </tr>
                    <tr>
                        <td className="bg-grey py-2 px-3 fsc-5">{dateFormat(orderDetails?.created_at)}</td>
                        <td className="bg-grey py-2 px-3 fsc-5">{orderDetails?.id}</td>
                        <td className="bg-grey py-2 px-3 fsc-5">{orderDetails?.product_name}</td>
                        <td className="bg-grey py-2 px-3 fsc-5">{orderDetails?.quantity}</td>
                        <td className="bg-grey py-2 px-3 fsc-5">{orderDetails?.total_price}</td>
                        <td className="bg-grey py-2 px-3 fsc-5">{getStatus(orderDetails?.order_status)}</td>

                    </tr>
                </table>
            </div>
            {orderDetails &&
                <div className="d-flex justify-content-end mt-3">
                    <Button
                        onClick={() => generateShopInvoice([orderDetails])}
                        variant="contained"
                        className="backcolorPrimary my-5 ms-4"
                        style={{ textTransform: "initial" }}
                    >
                        Print
                    </Button>
                </div>}
        </>
    )

}

export default MerchandiseTable