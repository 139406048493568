import React, { useEffect, useState } from "react";
import BannerComponent from "./BannerComponent";
import vouchersidebanner from "../../assets/img/voucher/romantic-surpri.webp";
import Menu from "./Menu";
import TrendingProducts from "./TrendingProducts";
import FlightResult from "./FlightResult";
import "./home.css";
import PointsComponent from "../commonComponents/PointsComponent";
import { getUserPointsApi } from "../../Api/Points";
import { setUserPointsRedux } from "../../redux/Order/OrderAction";
import { getSettings } from "../../Api/SettingsApi";
import { setSettingsData } from "../../redux/Settings/settingsAction";
import { useDispatch } from "react-redux";

export default function Home() {
  const dispatch = useDispatch()

  const [bannerShow, setbannerShow] = useState(true);
  const [isLoading, setIsLoding] = React.useState(false);

  useEffect(() => {

    preFetchData();

  }, []);

  const preFetchData = async () => {
    setIsLoding(true);
    try {
      await getSettingsData();
      setIsLoding(false);
    } catch (error) {
      setIsLoding(false);
    }
    setIsLoding(true);
    try {
      await getUserPoints();
      setIsLoding(false);
    } catch (error) {
      setIsLoding(false);
    }
  }

  const getUserPoints = async () => {
    const res = await getUserPointsApi();
    dispatch(setUserPointsRedux(res.data?.result?.Table1[0]?.AvailablePoints));
  }

  const getSettingsData = async () => {
    const res = await getSettings();
    dispatch(setSettingsData(res.data?.result));
  }


  return (
    <div className="w-100 d-flex mobileStack ">
      <div className="commonw50 mobileBox mainLeft">

        <div className="pe-3 pt-3">
          <PointsComponent />
        </div>

        <div className="d-flex flex-column align-items-center">

          <h1 align="center" className="mt-4">
            Give yourself the <strong>Freedom to Choose!</strong>
          </h1>
          <Menu />
          <TrendingProducts />
        </div>
      </div>
      <div className="commonw50 mobileBox">
        {bannerShow ? (
          <BannerComponent
            heading={"Welcome to the"}
            text={"World of Endless Rewards!"}
            banner={vouchersidebanner}
            button="true"
          />
        ) : (
          <FlightResult />
        )}
      </div>
    </div>
  );
}
