import React, { useEffect, useState } from "react";
import user from "../../assets/img/voucher/user.png";
import Avatar from "@mui/material/Avatar";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import OtpInput from "react-otp-input";
import {
  Button,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
} from "@mui/material";
import { resetPasswordApi, userIdentifierApi } from "../../Api/Auth";
import { LoadingButton } from "@mui/lab";

function ForgetPassword({ setModelShow, }) {

  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [otp, setOtp] = useState("");
  const [isLoading, setIsLoding] = useState(false);
  const [message, setMessage] = useState("");
  const [otpScr, setOtpScr] = useState(false);
  const [error, setError] = useState({ password: "", username: "", otp: "" });

  // to hide and show password
  const [passShowHide, setPassShowHide] = useState(false);

  const togglePassword = () => {
    setPassShowHide((prevState) => !prevState);
  };


  useEffect(() => {
    setMessage("");
    setIsLoding(false);
  }, [username, password, otp]);

  // Email or username input handler
  const userNameHandler = (e) => {
    const target = e.target;
    var value = target.value.toLowerCase();
    setUserName(value);
    setError({ ...error, username: "" });
  };

  // Email or username validation
  const userNameValidate = () => {
    if (username === "" || username === null) {
      setError({ ...error, username: "Please enter email or username" });
      return false;
    }

    return true;
  };


  const sendVerificationCode = async () => {
    let val = userNameValidate();
    if (val) {
      setIsLoding(true);
      let res = await userIdentifierApi(username);
      if (res?.data?.status === "success") {
        setOtpScr(true);
        setIsLoding(false);
      } else if (res?.data?.status === "error") {
        if (res?.data?.code === 200) {
          setMessage("Password updated successfuly.");
        } else if (res?.data?.code === 404) {
          setMessage("If you are a registered user in our system, you will receive your password reset information on your registered email.");
        } else if (res?.data?.code === 429) {
          setMessage("OTP already sent!");
          setOtpScr(true);
        } else {
          setMessage("Something went wrong. please try again.");
        }
        setIsLoding(false);
      }
    }
  }


  // Password input handler
  const passwordHandler = (e) => {
    const target = e.target;
    var value = target.value;
    setPassword(value);
    setError({ ...error, password: "" });
  };

  // OTP input handler
  const OTPHandler = (e) => {
    setOtp(e);
    setError({ ...error, otp: "" });
    setMessage("");
  };

  // Password Validation
  const passwordValidate = () => {
    var passRegex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_])(?=.{8,}$)"
    );

    if (password === "" || passRegex.test(password) === false) {
      setError({
        ...error,
        password: `Minimum 8 characters, at least one uppercase letter,
      one lowercase letter, one number and one special character`,
        username: "",
      });
      return false;
    } else if (otp.length !== 6) {
      setError({ ...error, otp: "Please enter OTP" });
      return false;
    }

    return true;
  };


  const submitHandler = async () => {
    let val = passwordValidate();
    if (val) {
      setIsLoding(true);
      let res = await resetPasswordApi(username, password, otp);
      if (res?.data?.code === 406) {
        setMessage("Invalid OTP");
      } else if (res?.data?.code === 200) {
        setMessage(res?.data?.message);
        setUserName("");
        setOtp("");
        setPassword("");
        // setModelShow("signin")
      }
      setIsLoding(false);
    }
  }

  return (
    <div className="">
      {!otpScr ? (
        <div style={{ paddingTop: "20px", paddingLeft: "30px", paddingRight: "30px" }}>
          <Avatar className="mui-useravtar my-3" src={user} />
          <InputLabel>Email Address</InputLabel>
          <OutlinedInput
            className="InputBox"
            id=""
            type="text"
            size="small"
            sx={{
              minWidth: "400px",
              height: "40px",
            }}
            name="username"
            value={username}
            autoFocus={username ? true : false}
            onChange={userNameHandler}
          />
          {error.username ? (
            <InputLabel className="text-danger">{error.username}</InputLabel>
          ) : null}

          <div className="my-4">
            <Stack spacing={3} direction="row">
              <Button
                variant="outlined"
                className={`borderButton`}
                onClick={() => setModelShow("signin")}
              >
                Back
              </Button>
              <LoadingButton
                className={isLoading ? "fillBtn-loader-true" : "fillBtn-loader"}
                loading={isLoading}
                variant="outlined"
                onClick={() => sendVerificationCode()}
              >
                {isLoading ? "" : "Next"}
              </LoadingButton>
            </Stack>
          </div>
        </div>
      ) : (
        <div style={{ paddingTop: "20px", paddingLeft: "30px", paddingRight: "30px" }}>
          <Avatar className="mui-useravtar my-3" src={user} />
          <InputLabel>OTP sent through registered email</InputLabel>
          <div className="my-3">
            <InputLabel>Password</InputLabel>
            <OutlinedInput
              className="InputBox"
              id="outlined-adornment-password"
              type={passShowHide ? "text" : "password"}
              size="small"
              sx={{
                minWidth: "400px",
                height: "40px",
              }}
              endAdornment={
                <InputAdornment style={{ marginLeft: "0" }} position="end">
                  <IconButton
                    aria-label="Search by brand"
                    edge="end"
                    onClick={() => togglePassword()}
                  >
                    {passShowHide ? (
                      <RemoveRedEyeIcon />
                    ) : (
                      <VisibilityOffIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              }
              name="forgetPassword"
              onChange={passwordHandler}
            />
            {error.password ? (
              <InputLabel className="loginErrorLable text-danger ">
                {error.password}
              </InputLabel>
            ) : null}

            <div className="my-4">
              <div className="col-sm-12">
                <OtpInput
                  value={otp}
                  onChange={OTPHandler}
                  numInputs={6}
                  separator={<span className="mx-2"></span>}
                  isInputNum={true}
                  shouldAutoFocus={true}
                  inputStyle={"otp-input-style"}
                //containerStyle={"px-4 py-4"}
                />
              </div>
              {error.otp ? (
                <label className="text-error">{error.otp}</label>
              ) : null}
            </div>
          </div>

          <div className="my-4">
            <Stack spacing={3} direction="row">
              <Button
                variant="outlined"
                className={`borderButton`}
                onClick={() => { setOtpScr(false); setMessage("") }}
              >
                Back
              </Button>
              <LoadingButton
                className={isLoading ? "fillBtn-loader-true" : "fillBtn-loader"}
                loading={isLoading}
                variant="outlined"
                onClick={() => submitHandler()}
              >
                {isLoading ? "" : "Submit"}
              </LoadingButton>
            </Stack>
          </div>
        </div>
      )}

      {message &&
        <div className="loginErrorLable" style={{ paddingTop: "20px", paddingLeft: "30px", paddingRight: "30px" }}>
          <span className="font-14 font-bold">
            {message && message}
          </span>
        </div>
      }


    </div>
  );
}

export default ForgetPassword;
