import moment from "moment";
import { useState } from "react";
import { useEffect } from "react";


export const nextYearRange = () => {
  return new Date(new Date().setFullYear(new Date().getFullYear() + 1));
};

export const last18YearRange = () => {
  let d = new Date(new Date().setFullYear(new Date().getFullYear() - 18));
  return moment(new Date(d)).format("YYYY-MM-DD");

};

export const nextDayDate = (d) => {
  return new Date(new Date().setDate(d.getDate() + 1));
};

export const dateFormat = (d) => {
  return moment(d).format('DD/MM/YYYY');
};

export const todayDate = () => {
  return moment(new Date()).format("YYYY-MM-DD");
};

export const minYearCalculate = (y) => {
  let year = moment().subtract(y, 'year');
  return moment(year).format('YYYY/MM/DD');
};

export const maxYearCalculate = (y) => {
  let year = moment().subtract(y, 'year');
  return moment(year).format("yyyy-mm-dd");
};

export const getAge = (dateString) => {
  var today = new Date();
  var birthDate = new Date(dateString);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}


export const getStatus = (e) => {
  if (e === 'successful') {
    return "Successful";
  } else if (e === 'in_progress') {
    return "Progress";
  } else if (e === 'created') {
    return "Created";
  }

}

export const getAccountStatus = () => { let res = Boolean(localStorage.getItem("loggedIn")); return res }

export const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);
  return debouncedValue;
}
