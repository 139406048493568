import React from 'react'
import { Card, CardMedia, CardContent, Typography } from '@mui/material';
import useStyle from './style';
import bgImgArray from "../../../assets/img/index";
import banner from "../../../assets/img/Attraction/banner.png";


const AttractionCard = ({ item, title, imgUrl, currency }) => {

    const classess = useStyle();

    const trimIdHandler = (item) => {
        let id = item;
        let val = id.slice(0, 50);
        return val;
    };

    return (
        // <Card className={classess.card} style={{ width: `100%`, }} key={imgUrl}>
        //     <div className={classess.cardImageBox} style={{ backgroundColor: `${color}` }}>
        //         <CardMedia className={classess.cardImage}
        //             image={imgUrl ? imgUrl : banner}
        //             // image={banner}
        //             title={title}
        //         />
        //     </div>
        //     <CardContent className={classess.cardTitle}>
        //         <Typography gutterBottom variant="p" className={item ? 'd-block mb-2 typotext two-line-dis play' : 'd-block m-0'} maxHeight={'27px'}>
        //             {title}
        //         </Typography>

        //     </CardContent>
        // </Card>
        <div class="card card--1 ">

            <div class="card__img"></div>
            <div classname="card_link">
                <div class="card__img--hover" style={{ backgroundImage: `url(${imgUrl ? imgUrl : banner})` }}>
                </div>
            </div>
            <div class="card__info">
                {/* <span class="card__category"> Recipe</span> */}
                <h3 class="card__title">{trimIdHandler(title)}</h3>
                <span class="card__by">{currency?.currency + " " + item?.price} </span>

            </div>
        </div>
    )
}

export default AttractionCard