import Attraction from "../components/Atrractions/Attraction";
import Home from "../components/travel/Home";
import Travel1 from "../components/travel/Travel1";
import Voucher from "../components/voucher/Voucher";
import Shop from "../components/shop/Shop";
import AccountView from "../components/account/AccountView";
import SettingView from "../components/setting/SettingView";
import SuccessPage from "../components/Success Payment/SuccessPage";
import NotFoundScreen from "../components/common/NotFoundScreen";


export const PrivateRoutes = [
    { url: '/', component: <Home /> },
    { url: '/travel', component: <Travel1 /> },
    { url: '/voucher', component: <Voucher /> },
    { url: '/shop', component: <Shop /> },
    { url: '/attraction', component: <Attraction /> },
    { url: '/account', component: <AccountView /> },
    { url: '/setting', component: <SettingView /> },
    { url: '/success', component: <SuccessPage /> },
    { url: '*', component: <NotFoundScreen /> },

]