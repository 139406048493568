import ApiToken from "./apiToken"



export const getUserPointsApi = async () => {
    const response = await ApiToken.get(`/v1/points_handler/pointBalance`);
    return response;
};

export const postUserPointEarnApi = async (invoiceNo, invoiceAmount) => {
    let payload = {
        "invoice_no": invoiceNo,
        "invoice_amount": invoiceAmount
    }
    const response = await ApiToken.post(`/v1/points_handler/earn`, payload);
    return response;
};

export const postUserPointBurnApi = async (payload) => {
    const response = await ApiToken.post(`/v1/points_handler/burn`, payload);
    return response;
};

