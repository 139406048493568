import React from 'react'
import Sidebar from '../sidebar/leftSidebar/SideBar';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';

const NotFoundScreen = () => {

    return (
        <div className="d-flex flex-column justify-content-center w-100" >
            <div className="d-flex flex-column align-items-center mt-4">
                <ReportProblemIcon style={{ color: 'var(--primayColor)', fontSize: '80px' }} />
                <h2 className="mt-3">404</h2>
                <span className="fw-bold-c mt-3">
                    Page not found
                </span>
                <br />
            </div>
        </div>
    )
}

export default NotFoundScreen