import React from 'react'
import { Card, CardMedia, CardContent, Typography } from '@mui/material';
import useStyle from './style';
import bgImgArray from "../../../assets/img/index";
import { Diversity1Outlined } from '@mui/icons-material';

const VoucherCard = ({ title, imgUrl, type, item, color, hardisk, productColor, rating, totalRevies, price, discount, delivery }) => {
    const classess = useStyle();

    const trimIdHandler = (item) => {
        let id = item;
        let val = id.slice(0, 50);
        return val;
    };

    return (
        // <Card className={classess.card} style={{ width: `100%` }} key={imgUrl}>
        //     <div className={classess.cardImageBox} style={{ backgroundColor: `${color}` }}>
        //         <CardMedia className={classess.cardImage}
        //             image={bgImgArray[imgUrl] || imgUrl}
        //             title={title}
        //         />
        //     </div>
        //     <CardContent className={classess.cardTitle}>
        //         {/* <Typography gutterBottom variant="p" className={hardisk ? 'd-block mb-2 typotext' : 'd-block m-0'} maxHeight={'45px'}> */}
        //         <Typography gutterBottom variant="p" className={hardisk ? 'd-block mb-2 typotext two-line-dis play' : 'd-block m-0'} maxHeight={'45px'}>
        //             {title}
        //         </Typography>
        //         {
        //             hardisk && <> <Typography gutterBottom variant="p" className='d-block mb-1 typotext'>
        //                 ({hardisk}) - {productColor}
        //             </Typography>

        //                 <Typography gutterBottom variant="p" className='d-block mb-2 typotext'>
        //                     {price}({totalRevies})<br />
        //                     {discount} Discount
        //                 </Typography>

        //                 <Typography gutterBottom variant="p typotext">
        //                     {delivery} Delivery
        //                 </Typography></>
        //         }

        //     </CardContent>
        // </Card>
        <div class="card card--1 ">

            <div class="card__img"></div>
            <div classname="card_link">
                <div class="card__img--hover" style={{ backgroundImage: `url(${bgImgArray[imgUrl] || imgUrl})` }}>
                    {/* <img src={bgImgArray[imgUrl] || imgUrl} alt="logos" style={{ width: '100%' }} /> */}
                </div>
            </div>
            <div class="card__info">
                {/* <span class="card__category"> Recipe</span> */}
                <h3 class="card__title">{trimIdHandler(title)}</h3>
                {type === "range" ?
                    <span class="card__by">{item?.country?.currency + " " + item?.min_amount} - {item?.country?.currency + " " + item?.max_amount}</span>
                    :
                    <span class="card__by">{item?.country?.currency + " " + Math.min(...item?.denominations && item?.denominations.map(item => item.denomination_value))} - {item?.country?.currency + " " + Math.max(...item?.denominations && item?.denominations.map(item => item.denomination_value))}</span>
                }
            </div>
        </div>
    )
}

export default VoucherCard