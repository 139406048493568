/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import { Button } from "@mui/material";
import SettingLeft from "./settingLeft/SettingLeft";
import "./setting.css";
import SettingRight from "./settingRight/SettingRight";
import { useDispatch, useSelector } from "react-redux";
import { setFlightDetails, setFlightScreenStep } from "../../redux/Flight/flightAction";

const SettingContainer = () => {
  const dispatch = useDispatch();

  const settingsState = useSelector(
    (state) => state?.settingState?.settingResponse
  );

  const [moduleNames, setModuleNames] = useState({
    siteSettings: true,
    flight: false,
    hotel: false,
    car: false,
    voucher: false,
    shop: false,
    atrractionActivities: false,
  });

  const [BannerTitle, setBannerTitle] = useState({
    flightBannerTitle: "",
    hotelBannerTitle: "",
    carBannerTitle: "",
    voucherBannerTitle: "",
    shopBannerTitle: "",
    atrractionBannerTitle: "",
  });


  const [flightImgFiles, setfFlightImgFiles] = useState([]);
  const [hotelImgFiles, setHotelImgFiles] = useState([]);
  const [carImgFiles, setCarImgFiles] = useState([]);
  const [voucherImgFiles, setVoucherImgFiles] = useState([]);
  const [shopImgFiles, setShopImgFiles] = useState([]);
  const [atrractionImgFiles, setAtrractionImgFiles] = useState([]);
  const [siteLogoImgFiles, setSiteLogoImgFiles] = useState([]);


  // Travel module step reset to 0
  useEffect(() => {
    dispatch(setFlightScreenStep(0));
    dispatch(setFlightDetails(null));
  }, []);

  useEffect(() => {
    setBannerTitle({
      ...BannerTitle,
      flightBannerTitle:
        settingsState && settingsState?.flight?.flightBannerTitle,
      hotelBannerTitle: settingsState && settingsState?.hotel?.hotelBannerTitle,
      carBannerTitle: settingsState && settingsState?.car?.carBannerTitle,
      voucherBannerTitle:
        settingsState && settingsState?.voucher?.voucherBannerTitle,
      shopBannerTitle: settingsState && settingsState?.shop?.shopBannerTitle,
      atrractionBannerTitle:
        settingsState && settingsState?.atrractive?.atrractiveBannerTitle,
    });
  }, [settingsState]);

  const flightBannerTitleHandler = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    if (name === "flightBannerTitle") {
      setBannerTitle({ ...BannerTitle, flightBannerTitle: value });
    } else if (name === "hotelBannerTitle") {
      setBannerTitle({ ...BannerTitle, hotelBannerTitle: value });
    } else if (name === "carBannerTitle") {
      setBannerTitle({ ...BannerTitle, carBannerTitle: value });
    } else if (name === "voucherBannerTitle") {
      setBannerTitle({ ...BannerTitle, voucherBannerTitle: value });
    } else if (name === "shopBannerTitle") {
      setBannerTitle({ ...BannerTitle, shopBannerTitle: value });
    } else if (name === "atrractionBannerTitle") {
      setBannerTitle({ ...BannerTitle, atrractionBannerTitle: value });
    }
  };

  const buttonsHandler = (e) => {
    let name = e.target.name;
    // let value = e.target.value;
    setModuleNames({ [name]: true });
  };


  const submit = () => {
    let obj = {
      "partner_id": "string",
      "site_settings": {
        "site_details": {
          "primary_color": BannerTitle,
          "site_logo": siteLogoImgFiles
        },
        "flight": {
          "flight_banner_tittle": BannerTitle.flightBannerTitle,
          "flight_banner_image": flightImgFiles
        },
        "hotel": {
          "hotel_banner_tittle": BannerTitle.hotelBannerTitle,
          "hotel_banner_image": hotelImgFiles
        },
        "car": {
          "car_banner_tittle": BannerTitle.carBannerTitle,
          "car_banner_image": carImgFiles
        },
        "voucher": {
          "voucher_banner_tittle": BannerTitle.voucherBannerTitle,
          "voucher_banner_image": voucherImgFiles
        },
        "shop": {
          "shop_banner_tittle": BannerTitle.shopBannerTitle,
          "shop_banner_image": shopImgFiles
        },
        "attractive": {
          "attractive_banner_tittle": BannerTitle.atrractionBannerTitle,
          "attractive_banner_image": atrractionImgFiles
        }
      }
    }
    console.log(obj)
  };

  return (
    <>
      {/* <Stack direction="row" spacing={2} style={{ width: '100%',margin:'0' }} className='profileMobileStack'>
          <Box p={0} style={{width:'50%', zIndex: 2,margin:'0', padding: '40px 30px 50px 20px' }} className='profileMobileBox'>
            <h1 style={{paddingLeft:'10px', marginBottom: '20px'}} className='headingMobile'>Contact Us</h1>
            <Stack direction="row" spacing={2} className='profileMobileStack'>
              <Box  className='mobileProfile'>
                <ProfilePic />
              </Box>
              <Box className='mobileEdit'>
                <ContactUs />
              </Box>
            </Stack>
          </Box>
  
          <AccountDetails />
  
        </Stack> */}
      <div
        className="settingMobileContainer d-flex flex-direction-row"
        style={{ width: "100%", margin: "0" }}
      >
        {/* Modules */}
        <div
          className="settingMobileBox"
          style={{
            width: "50%",
            zIndex: 2,
            margin: "0",
            padding: "40px 30px 50px 20px",
          }}
        >
          <Button
            id="primaryColor"
            className={`${moduleNames.siteSettings === true
              ? "settingsButtonActive"
              : "settingsButtons"
              } d-flex justify-content-between align-items-center`}
            name="siteSettings"
            onClick={buttonsHandler}
            variant="contained"
          >
            Site Settings
          </Button>

          <Button
            id="primaryColor"
            className={`${moduleNames.flight === true
              ? "settingsButtonActive"
              : "settingsButtons"
              } d-flex justify-content-between align-items-center`}
            name="flight"
            onClick={buttonsHandler}
            disabled={true}
            variant="contained"
          >
            Flight
          </Button>

          <Button
            id="primaryColor"
            className={`${moduleNames.hotel === true
              ? "settingsButtonActive"
              : "settingsButtons"
              } d-flex justify-content-between align-items-center`}
            name="hotel"
            onClick={buttonsHandler}
            disabled={true}
            variant="contained"
          >
            Hotel
          </Button>

          <Button
            id="primaryColor"
            className={`${moduleNames.car === true
              ? "settingsButtonActive"
              : "settingsButtons"
              } d-flex justify-content-between align-items-center`}
            name="car"
            onClick={buttonsHandler}
            disabled={true}
            variant="contained"
          >
            Car
          </Button>

          <Button
            id="primaryColor"
            className={`${moduleNames.voucher === true
              ? "settingsButtonActive"
              : "settingsButtons"
              } d-flex justify-content-between align-items-center`}
            name="voucher"
            onClick={buttonsHandler}
            disabled={true}
            variant="contained"
          >
            Voucher
          </Button>

          <Button
            id="primaryColor"
            className={`${moduleNames.shop === true
              ? "settingsButtonActive"
              : "settingsButtons"
              } d-flex justify-content-between align-items-center`}
            name="shop"
            onClick={buttonsHandler}
            disabled={true}
            variant="contained"
          >
            Shop
          </Button>

          <Button
            id="primaryColor"
            className={`${moduleNames.atrractionActivities === true
              ? "settingsButtonActive"
              : "settingsButtons"
              } d-flex justify-content-between align-items-center`}
            name="atrractionActivities"
            onClick={buttonsHandler}
            disabled={true}
            variant="contained"
          >
            Atrraction & Activities
          </Button>

          <div className="d-flex justify-content-between align-items-center">
            <div style={{ textAlign: "right" }}>
              <Button
                id="primaryBorderColor"
                variant="contained"
                className={`fillButton`}
              >
                Reset Settings
              </Button>
            </div>
           
          </div>
        </div>

        {/* Site Settings */}
        {moduleNames.siteSettings === true ? (
          <div
            className="settingMobileBox"
            style={{
              width: "50%",
              zIndex: 2,
              margin: "0",
              padding: "40px 30px 50px 20px",
            }}
          >
            <h1
              style={{ paddingLeft: "10px", marginBottom: "10px" }}
              className="headingMobile"
              id="primaryTextColor"
            >
              Site Settings
            </h1>
            <div className="d-flex flex-direction-row">
              <Box className="contactUsEdit w-100">
                <SettingRight
                  leftSideFormData={BannerTitle}
                  fileUploadState={siteLogoImgFiles}
                  setFileUploadState={setSiteLogoImgFiles}
                />
              </Box>
            </div>
          </div>
        ) : null}

        {moduleNames.flight === true ? (
          <div
            className="settingMobileBox"
            style={{
              width: "50%",
              zIndex: 2,
              margin: "0",
              padding: "40px 30px 50px 20px",
            }}
          >
            <h1
              style={{ paddingLeft: "10px", marginBottom: "10px" }}
              className="headingMobile"
            >
              Flight
            </h1>
            <div className="d-flex flex-direction-row">
              <Box className="contactUsEdit w-100">
                <SettingLeft
                  inputFieldName="flightBannerTitle"
                  fileUploadFieldName="FlightBannerImage"
                  inputValue={
                    BannerTitle.flightBannerTitle &&
                    BannerTitle.flightBannerTitle
                  }
                  inputFieldHandler={flightBannerTitleHandler}
                  fileUploadState={flightImgFiles}
                  setFileUploadState={setfFlightImgFiles}
                />
              </Box>
            </div>
          </div>
        ) : null}

        {moduleNames.hotel === true ? (
          <div
            className="settingMobileBox"
            style={{
              width: "50%",
              zIndex: 2,
              margin: "0",
              padding: "40px 30px 50px 20px",
            }}
          >
            <h1
              style={{ paddingLeft: "10px", marginBottom: "10px" }}
              className="headingMobile"
            >
              Hotel
            </h1>
            <div className="d-flex flex-direction-row">
              <Box className="contactUsEdit w-100">
                <SettingLeft
                  inputFieldName="hotelBannerTitle"
                  fileUploadFieldName="hotelBannerImage"
                  inputValue={BannerTitle.hotelBannerTitle}
                  inputFieldHandler={flightBannerTitleHandler}
                  fileUploadState={hotelImgFiles}
                  setFileUploadState={setHotelImgFiles}
                />
              </Box>
            </div>
          </div>
        ) : null}

        {moduleNames.car === true ? (
          <div
            className="settingMobileBox"
            style={{
              width: "50%",
              zIndex: 2,
              margin: "0",
              padding: "40px 30px 50px 20px",
            }}
          >
            <h1
              style={{ paddingLeft: "10px", marginBottom: "10px" }}
              className="headingMobile"
            >
              Car
            </h1>
            <div className="d-flex flex-direction-row">
              <Box className="contactUsEdit w-100">
                <SettingLeft
                  inputFieldName="carBannerTitle"
                  fileUploadFieldName="carBannerImage"
                  inputValue={BannerTitle.carBannerTitle}
                  inputFieldHandler={flightBannerTitleHandler}
                  fileUploadState={carImgFiles}
                  setFileUploadState={setCarImgFiles}
                />
              </Box>
            </div>
          </div>
        ) : null}

        {moduleNames.voucher === true ? (
          <div
            className="settingMobileBox"
            style={{
              width: "50%",
              zIndex: 2,
              margin: "0",
              padding: "40px 30px 50px 20px",
            }}
          >
            <h1
              style={{ paddingLeft: "10px", marginBottom: "10px" }}
              className="headingMobile"
            >
              Voucher
            </h1>
            <div className="d-flex flex-direction-row">
              <Box className="contactUsEdit w-100">
                <SettingLeft
                  inputFieldName="voucherBannerTitle"
                  fileUploadFieldName="carBannerImage"
                  inputValue={BannerTitle.voucherBannerTitle}
                  inputFieldHandler={flightBannerTitleHandler}
                  fileUploadState={voucherImgFiles}
                  setFileUploadState={setVoucherImgFiles}
                />
              </Box>
            </div>
          </div>
        ) : null}

        {moduleNames.shop === true ? (
          <div
            className="settingMobileBox"
            style={{
              width: "50%",
              zIndex: 2,
              margin: "0",
              padding: "40px 30px 50px 20px",
            }}
          >
            <h1
              style={{ paddingLeft: "10px", marginBottom: "10px" }}
              className="headingMobile"
            >
              Shop
            </h1>
            <div className="d-flex flex-direction-row">
              <Box className="contactUsEdit w-100">
                <SettingLeft
                  inputFieldName="shopBannerTitle"
                  fileUploadFieldName="shoptBannerImage"
                  inputValue={BannerTitle.shopBannerTitle}
                  inputFieldHandler={flightBannerTitleHandler}
                  fileUploadState={shopImgFiles}
                  setFileUploadState={setShopImgFiles}
                />
              </Box>
            </div>
          </div>
        ) : null}

        {moduleNames.atrractionActivities === true ? (
          <div
            className="settingMobileBox"
            style={{
              width: "50%",
              zIndex: 2,
              margin: "0",
              padding: "40px 30px 50px 20px",
            }}
          >
            <h1
              style={{ paddingLeft: "10px", marginBottom: "10px" }}
              className="headingMobile"
            >
              Atrraction & Activites
            </h1>
            <div className="d-flex flex-direction-row">
              <Box className="contactUsEdit w-100">
                <SettingLeft
                  inputFieldName="atrractionBannerTitle"
                  fileUploadFieldName="atrractionBannerImage"
                  inputValue={BannerTitle.atrractionBannerTitle}
                  inputFieldHandler={flightBannerTitleHandler}
                  fileUploadState={atrractionImgFiles}
                  setFileUploadState={setAtrractionImgFiles}
                />
              </Box>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default SettingContainer;
