import React from 'react'
import { Card, CardMedia, CardContent, Typography } from '@mui/material';
import useStyle from './style';
import bgImgArray from "../../../assets/img/index";

const Shopcard = ({ title, imgUrl, hardisk, currency, price }) => {
    const classess = useStyle();

    const trimIdHandler = (item) => {
        let id = item;
        let val = id.slice(0, 50);
        return val;
    };

    return (
        <>
            {/* <Card className={classess.card} style={{ width: `100%` }} key={imgUrl}>
                <div className={classess.cardImageBox} >
                    <CardMedia className={classess.cardImage}
                        image={bgImgArray[imgUrl] || imgUrl}
                        title={title}
                    />
                </div>
                <CardContent className={classess.cardTitle}>
                    <Typography title={title} gutterBottom variant="p" className={hardisk ? 'd-block mb-2 typotext' : 'd-block m-0'}>
                        {trimIdHandler(title)}
                        <span>...</span>
                    </Typography>

                    <Typography gutterBottom variant="p" className='d-block my-2 typotext'>
                        {currency} {price}<br />
                    </Typography>
                </CardContent>
            </Card> */}
            <div class="shop-card shop-card--1 ">

                <div class="shop-card__img"></div>
                <div classname="card_link">
                    <div class="shop-card__img--hover" style={{ backgroundImage: `url(${bgImgArray[imgUrl] || imgUrl})` }}>
                    </div>
                </div>
                <div class="shop-card__info">
                    {/* <span class="shop-card__category"> Recipe</span> */}
                    <h3 class="shop-card__title">{trimIdHandler(title+"currency price currencycurrency currency  price ")}</h3>
                    <span class="shop-card__by">{currency + " " + price} </span>

                </div>
            </div>

        </>

    )
}

export default Shopcard