import React, { useState } from 'react';
import useStyle from '../RightSideSection';
import PrintIcon from '@mui/icons-material/Print';
import { Button, CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useQuery } from 'react-query';
import { getProductOrderList } from '../../../../Api/OrderApi';
import { dateFormat, getStatus } from '../../../../utils/constantFunctions';
import logos from "../../../../assets/img/sidebar/ehadayaDarkLogo.png"
import { Stack } from '@mui/system';
import jsPDF from "jspdf";
import "jspdf-autotable";
import "jspdf-invoice-template";
import { useSelector } from 'react-redux';
import CommonPagination from '../../../commonComponents/CommonPagination';

const VoucherOrder = () => {
    const classess = useStyle();

    const [skip, setSkip] = useState(0);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);

    const { isLoading, error, data } = useQuery(['voucher_order_list', skip, limit], () => getProductOrderList("giftcard", skip, limit));


    const settingState = useSelector((state) => state?.settingState?.settingResponse?.site_details);
    const accountDetailsState = useSelector((state) => state?.settingState?.accountDetails);

    const generateInvoice = (data) => {
        const doc = new jsPDF("p", "pt");

        // Add title
        const title = "Invoice";
        const titleWidth =
            doc.getStringUnitWidth(title) *
            doc.internal.getFontSize() /
            doc.internal.scaleFactor;
        const titleX = (doc.internal.pageSize.width - titleWidth) / 2;

        // Move the "Invoice" title to the top of the page
        const titleY = 60;
        doc.setFontSize(16);
        doc.text(title, titleX, titleY);

        // Add logo
        // const logo = logos;
        const logo = `${settingState?.dark_logo}`;
        doc.addImage(logo, "PNG", 50, 130, 80, 19);
        doc.addImage(logo, "JPEG", 50, 130, 80, 19);

        // Add date
        const date = dateFormat(data?.[0]?.created_at);
        doc.setFontSize(12);
        doc.text(`Date: ${date}`, 40, 220);

        // Get contact information
        const { firstname, lastname, email, contact_no } = accountDetailsState;
        const contactInfo = `${firstname} ${lastname}\n${email}\n${contact_no}`;
        doc.setFontSize(12);
        doc.text(contactInfo, 40, 250);

        // Add ID number
        doc.setFontSize(12);
        doc.text(`Order ID: ${data?.[0]?.id}`, doc.internal.pageSize.width - 235, 222);

        const domainName = process.env.REACT_APP_DOMAIN_NAME;

        // company information
        const companyInfo = `\ninfo@ehadaya.com`;

        // Add company information to PDF
        const companyInfoX = doc.internal.pageSize.width - 235;
        doc.text(domainName, companyInfoX, 250);
        doc.text(companyInfo, companyInfoX, 250);

        // Add table
        doc.autoTable({
            startY: 350,
            head: [["Voucher Name", "Quantity", "Amount"]],
            body: data.map((row) => [row?.giftcard.default_title, row?.quantity, row?.total_price]),
            margin: { top: 20 },
            tableWidth: "auto",
            cellPadding: { top: 8, right: 8, bottom: 8, left: 8 }
        });

        // Add subtotal, tax, and total below the table
        const tableBottomY = doc.lastAutoTable.finalY + 20;
        const subtotal = data.reduce((acc, row) => acc + row?.total_price, 0);
        const orderPrice = data.reduce((acc, row) => acc + (row?.order_price), 0);

        const tax = subtotal * 0.1;
        const total = subtotal + tax;
        doc.setFontSize(12);
        doc.text(`Total: AED ${subtotal.toFixed(2)}`, doc.internal.pageSize.width - 200, tableBottomY + 10);
        doc.text(`Discount: AED ${(subtotal - orderPrice).toFixed(2)}`, doc.internal.pageSize.width - 200, tableBottomY + 30);
        // doc.text(`Tax: AED ${tax.toFixed(2)}`, doc.internal.pageSize.width - 200, tableBottomY + 20);
        doc.text(`Order Amount: AED ${orderPrice.toFixed(2)}`, doc.internal.pageSize.width - 200, tableBottomY + 50);

        doc.save(`invoice.pdf`);
    };

    return (
        <>
            {isLoading ?
                <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    marginTop={'5%'}
                >
                    <CircularProgress color="secondary" />
                </Stack> :

                <TableContainer className={classess.tableBox} >
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell className={
                                    classess.tableTitle
                                }>Sr.</TableCell>
                                <TableCell className={
                                    classess.tableTitle
                                }>Date</TableCell>
                                <TableCell className={
                                    classess.tableTitle
                                }>Voucher Name/Type</TableCell>
                                <TableCell className={
                                    classess.tableTitle
                                }>Voucher Amount</TableCell>
                                <TableCell className={
                                    classess.tableTitle
                                }>Order Amount</TableCell>
                                <TableCell className={
                                    classess.tableTitle
                                }>Quantity</TableCell>
                                <TableCell className={
                                    classess.tableTitle
                                }>Status
                                    {/* (E.g. Active/Redeemed/Expired) */}
                                </TableCell>
                                <TableCell className={
                                    classess.tableTitleLast
                                }>Download Invoice</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data && data?.data?.result && data?.data?.result.map((row, index) => (
                                <TableRow
                                    key={index}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell className={
                                        classess.tableTD
                                    } component="th" scope="row">
                                        {index + 1}
                                    </TableCell>
                                    <TableCell className={
                                        classess.tableTD
                                    }>{dateFormat(row.created_at)}</TableCell>
                                    <TableCell className={
                                        classess.tableTD
                                    }>{row?.giftcard?.default_title}</TableCell>
                                    <TableCell className={
                                        classess.tableTD
                                    }>{row?.total_price}</TableCell>
                                    <TableCell className={
                                        classess.tableTD
                                    }>{row?.order_price}</TableCell>
                                    <TableCell className={
                                        classess.tableTD
                                    }>{row.quantity}</TableCell>
                                    <TableCell className={
                                        classess.tableTD
                                    }>{getStatus(row?.order_status)}</TableCell>
                                    <TableCell className={
                                        classess.tableTDLast
                                    }>
                                        <Button variant="text" className={classess.tableBtn} onClick={() => generateInvoice([row])}>
                                            <PrintIcon />
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <CommonPagination limit={limit} setPage={setPage} setSkip={setSkip} page={page} setLimit={setLimit} skip={skip} total={data?.data?.count} />
                </TableContainer >}
        </>
    )
}

export default VoucherOrder;