import { Alert, Snackbar } from '@mui/material';
import * as React from 'react';


export default function SnackbarAlert({ message, open, vertical, horizontal, handleClose, severity }) {


    return (
        <>
            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={open}
                onClose={handleClose}
                key={vertical + horizontal}
                autoHideDuration={6000}
            >
                <Alert
                    //  onClose={handleClose}
                    severity={severity}
                    sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>
        </>
    );
}
