import Api from "./api"
import { TRIPXOXO_CUSTOMER_ID } from '../utils/constantData';

export const getAttractionsLangingSrcProducts = async () => {
    const response = await Api.get('https://run.mocky.io/v3/0a7eff3b-b943-434b-b217-ab1e900a9612')
    return response;
};

export const getAttractionsProducts = async ({ queryKey }) => {
    const [key, fields, search] = queryKey;

    const response = await Api.get(`/v1/experiences/products${fields?.selectedCountry?.id ? "?countryId=" + fields?.selectedCountry.id : ""}${fields?.selectedCity?.id ? "&cityId=" + fields?.selectedCity.id : ""}${fields?.selectedCategory?.id ? "&categories=" + fields?.selectedCategory.id : ""}${search ? "&query=" + search : ""}`)

    //${fields?.selectedCurrency?.currency ? "&currency=" + fields?.selectedCurrency.currency : ""}
    //  const response = await Api.get('https://run.mocky.io/v3/7773c957-5f15-42b7-88ad-ca61bcd14ab2')
    return response;
};

export const getAttractionsProductsCategories = async () => {
    const response = await Api.get('/v1/experiences/products')

    // const response = await Api.get('https://run.mocky.io/v3/e4cd2838-c8d7-48bb-b09d-32e1ab5f02db')
    return response;
};

export const getAttractionsCurrency = async () => {
    const response = await Api.get(`/v1/experiences/currency`)
    return response;
};

export const getAttractionsCountry = async () => {
    const response = await Api.get(`/v1/experiences/country`)
    return response;
};

export const getAttractionsCity = async () => {
    const response = await Api.get(`/v1/experiences/city`)
    return response;
};

export const getAttractionsProductsDetails = async (id, currency) => {
    const response = await Api.get(`/v1/experiences/product/${id}?currency=${currency}&customerId=${TRIPXOXO_CUSTOMER_ID}`)

    //  const response = await Api.get('https://run.mocky.io/v3/d43bf861-bf2e-44ae-999b-21ada1a2f6d3')
    return response;
};

export const getAttractionsAvailability = async (obj) => {
    const response = await Api.get(`https://run.mocky.io/v3/d7f899c1-f486-4284-9eda-07236a6685a8`)
    // const response = await Api.get(`/v1/experiences/availability?productId=${obj?.productId}&customerId=${TRIPXOXO_CUSTOMER_ID}&date=${obj?.date}&tickets=${JSON.stringify(obj?.tickets)}`);


    return response;
};