import Api from "./api"
import ApiToken from "./apiToken"
import { CLIENT_ID } from "../utils/constantData";

export const getSettings = async () => {
    const response = await Api.get(`/v1/site_settings/${CLIENT_ID}`)
    return response;
};

export const postSettings = async () => {
    const response = await Api.post('https://run.mocky.io/v3/9a6c308b-f5b7-4ff7-b251-3adbb0860541')
    return response;
};

export const putSettings = async (payload) => {
    const response = await Api.put(`/v1/site_settings/${CLIENT_ID}`, payload)
    return response;
};