import { makeStyles } from '@mui/styles';

const useStyle = makeStyles({
    container: {
        padding: '0px 0px 0px 30px',
        maxWidth: '100%',
    },
    card: {
        opacity: 1,
        border: '1px solid #F1F1F1',
        boxShadow: 'none !important',
        marginBottom: '1rem',
        cursor: 'pointer',
        transition: '0.3s all',
        '&:hover': {
            border: '1px solid var(--primayColor)',
        }

    },
    cardTitle: {
        background: '#F1F1F1 0% 0% no-repeat padding-box',
        padding: '8px 12px !important',
        opacity: 1,
        fontFamily: 'helvetica_neue65_medium',
        color: '#565656',
        fontSize: '16px',
        height: '80px'
    },
    cardImage: {
        maxWidth: '100%',
        maxHeight: '100%',
        width: '100%',
        height: '100%',
        backgroundSize: 'cover !important',
        backgroundPosition: 'center !important'
    },
    cardImageBox: {
        height: '171px',
        padding: '0px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'

    },


});

export default useStyle