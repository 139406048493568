import { CLIENT_ID } from "../utils/constantData";
import ApiToken from "./apiToken"

export const postOrderCreation = async (obj) => {
    const response = await ApiToken.post(`/v1/product_transaction`, obj)
    return response;
};


export const getProductOrderList = async (type, skip, limit) => {
    const response = await ApiToken.get(`/v1/product_transaction?skip=${skip}&limit=${limit}&buyer_id=${CLIENT_ID}&order_by=client&product_type=${type}`)
    return response;
};